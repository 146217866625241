import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useEffect, useState } from "react";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import TextArea from "Components/FormControl/TextArea/TextArea";
// SCSS
import styles from "./cancelNote.module.scss";
import CheckBox from "../../../../Components/FormControl/CheckBox/CheckBox";
import { REASON_LIST } from "Helpers/Constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalWithRefunds = ({
  showModal,
  pageTitle,
  handleCancelClick,
  handleSubmitClick,
  placeholder,
  totalRefundAmount = 0,
  summary,
}) => {
  const [addnote, setAddNote] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [refundAmountInitial, setRefundAmountInitial] = useState(0);
  const [error, setErrorMessage] = useState("");
  const [errorAmount, setErrorMessageAmount] = useState("");
  const [refundOptionValue, setRefundOptionValue] = useState(null);

  useEffect(() => {
    if (totalRefundAmount) {
      // setRefundAmount(totalRefundAmount);
      setRefundAmountInitial(totalRefundAmount);
    }
  }, [totalRefundAmount]);

  useEffect(() => {
    if(summary?.refund){
      setRefundAmount(summary?.refund);
    }
  }, [summary?.refund]);
  
  useEffect(() => {
    // setRefundAmount(0)
    setRefundOptionValue(1)
  }, [showModal]);

  const submit = (e) => {
    if(refundOptionValue === 1 && refundAmount <= 0 ){
      setErrorMessage(`Please enter amount and amount should be greater than 0`);
    }else{
      setErrorMessage("");
      setErrorMessageAmount("");
      handleSubmitClick(refundOptionValue, refundAmount);
    }
  };
  const handleSelectedReason = (val) => {
    setRefundOptionValue(parseInt(val));
  }
  // const { orderState } = useOrder();
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-4 py-0 pb-3`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            {pageTitle === "Replace Order" && <h3 className="text-center px-5" style={{ fontSize: "20px" }}>{`Replacing an order will allow you to send a replacement
without charging the customer again`}</h3>}
            <div className={`row flex-column px-2 ${styles.reasonCard_radio_button}`}>
              <h3 className="text-left">{`How much do you want to refund this order?`}</h3>
              <RadioBox
                selectedValue={refundOptionValue}
                onChange={(e) => {
                  handleSelectedReason(e);
                  setRefundAmount(refundAmountInitial);
                }}
                value={2}
                label={`Full Refund ${refundOptionValue === 2 ? "(" + refundAmount + ")" : ""}`}
              />
              <RadioBox
                selectedValue={refundOptionValue}
                onChange={(e) => {
                  handleSelectedReason(e);
                  setRefundAmount(summary?.refund);
                }}
                value={1}
                label="Partial Amount"
              />
              {refundOptionValue === 1 && (
                <div
                  className={`col-md-6 col-xs-12 mr-3 ${styles.marginAutoCustom}`}
                >
                  <TextField
                    className="w-100"
                    label={"Enter Amount"}
                    value={refundAmount}
                    onChange={(e) => {
                      setErrorMessageAmount("");
                      setRefundAmount(e.target.value);
                    }}
                    error={(Boolean(error) && refundOptionValue === 1) && error}
                    touched={(Boolean(error) && refundOptionValue === 1) && error}
                    helperText={(Boolean(error) && refundOptionValue === 1) && error}
                    disabled={checkBox ? true : false}
                    required
                  />
                </div>
              )}
              <RadioBox
                selectedValue={refundOptionValue}
                onChange={(e) => {
                  handleSelectedReason(e);
                  setRefundAmount("0");
                }}
                value={0}
                label="No Refund"
              />
            </div>
            <div className="row">
              <div className="col-12 my-3">
                <Button
                  onClick={submit}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalWithRefunds;
