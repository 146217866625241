import React, { useCallback } from "react";
import styles from "Components/NewOrder/index.module.scss";
import { useFormikContext } from "formik";
import { AppContext } from "Context/AppContext";
import { useEffect, useContext } from "react";
import { useOrderAttributeProvider } from "../context/order";
import { calcuateSummary, calculateKintsugiItemPrice } from "Context/OrderContext/OrderCalculation";
import DiscountModal from "./OrderDiscount/OrderDiscount";
import TaxModal from "./OrderTax/OrderTax";
import { useState } from "react";
import FundModal from "./OrderFund";
import { getEstimateTax } from "api/order";
import { orderObject } from "../utility/createOrderObject";
import { useUser } from "Context/UserContext/useUser";
import moment, { utc } from "moment";
import { utcTimeToDate } from "../utility/util";
import { ALLOW_KINTSUGI_TAX_DATE } from "Helpers/Constants";

const TotalSummary = (props) => {
  const formik = useFormikContext();
  const {
    summary,
    setSummary,
    module,
    orderDetail,
    modal,
    setModal,
    active,
  } = useOrderAttributeProvider();
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showTaxModal, setShowTaxModal] = useState(false);
  const [tempAddFundValue, setTempAddFundValue] = useState(summary.add_fund);
  const [tempReFundValue, setTempReFundValue] = useState(summary.refund);
  const { setIsLoading, showToastMessage } = useContext(AppContext);
  const { userState, reLogin } = useUser();

  //get login user information
  const getUser = async () => {
    if (userState?.userProfile?.id) {
      return userState?.userProfile?.id;
    } else {
      const user = await reLogin();
      return user.id;
    }
  };

  //fetch taxable amount and tax related data from API
  //this function will be called when zipcode is entered and anything changes made in dependencies fields
  const fetchTaxAmount = useCallback(async () => {
    try {
      const orderedDate = moment(orderDetail?.createdAt).format("YYYY-MM-DD HH:mm");
      const startDateKintsugiTax = ALLOW_KINTSUGI_TAX_DATE;
      if (
        formik.values.delivery_information?.zipcode?.length === 5 &&
        formik.values.delivery_information?.zipcode !== "" &&
        (formik.values.recipients[active]?.products?.length > 0 ||
          formik.values.recipients[active]?.addons?.length > 0) &&
        orderedDate > startDateKintsugiTax &&
        !formik.values.is_replace_order &&
        formik.values.payment_type !== "house_account"
      ) {
        //prepare order object for tax calculation
        const summary = calcuateSummary(formik);
        const receipients = calculateKintsugiItemPrice(summary, formik);
        formik.setValues((v) => {
          return {
            ...v,
            recipients: receipients,
          };
        });
        const user_id = await getUser();
        const order = orderObject(formik.values, summary, user_id);
        const postBody = {
          order: order,
        };
        //call API to get tax calculated amount
        const estimatedTaxRes = await getEstimateTax(postBody);
        if (estimatedTaxRes && estimatedTaxRes?.status === 200) {
          await updatedEstmatedResToRecData(estimatedTaxRes.data.data.data);
        } else {
          showToastMessage(
            undefined,
            estimatedTaxRes.data.message || "Error in tax calculation",
            "error",
            false
          );
        }
      }
    } catch (error) {
      console.log("error: ", error);
      showToastMessage(
        undefined,
        error.message || "Error in tax calculation",
        "error",
        false
      );
    }
  }, [
    formik.values.delivery_information.delivery_type,
    formik.values.delivery_information.zipcode,
    formik.values.discount.discount_percentage,
    formik.values.discount.discount_price,
    summary.total_qty,
    formik.values.delivery_information.storeId,
    formik.values.tax_id,
    formik.values.payment_type,
  ]);

  const updatedEstmatedResToRecData = async (data) => {
    const { order } = data;
    const {
      receipient,
      tax,
      is_tax_calculated_manually,
      tax_rate,
      taxable_amount,
    } = order[0];
    //update the tax amount and required related data to the recipient data
    const recipientsData = formik.values.recipients?.map((recipient, index) => {
      let recProductIndex = receipient.findIndex(
        (r) => r?.recipient_key === recipient?.recipient_key
      );
      receipient[recProductIndex]?.recipient_product?.map((product, index) => {
        if (product?.product_type == 0) {
          let productIndex = recipient.products.findIndex(
            (p) => p?.product_key === product?.product_key
          );
          if (productIndex !== -1) {
            recipient.products[productIndex].taxable_amount =
              +product?.taxable_amount || 0;
            recipient.products[productIndex].tax_rate = +product.tax_rate || 0;
            recipient.products[productIndex].tax = +product?.tax || 0;
            recipient.products[productIndex].kintsugi_product_id =
              product?.kintsugi_product_id || "";
            recipient.products[productIndex].kintsugi_item_price =
              +product?.kintsugi_item_price || 0;
            return recipient.products[productIndex];
          }
        } else if (product?.product_type == 1) {
          let productIndex = recipient.addons.findIndex(
            (p) => p?.product_key === product?.product_key
          );
          if (productIndex !== -1) {
            recipient.addons[productIndex].taxable_amount =
              +product?.taxable_amount || 0;
            recipient.addons[productIndex].tax_rate = +product?.tax_rate || 0;
            recipient.addons[productIndex].tax = +product?.tax || 0;
            recipient.addons[productIndex].kintsugi_product_id =
              product?.kintsugi_product_id || "";
            recipient.addons[productIndex].kintsugi_item_price =
              +product?.kintsugi_item_price || 0;
            return recipient.addons[productIndex];
          }
        }
      });
      return recipient;
    });

    //set the tax amount and updated recipients info to formik values
    formik.setValues((v) => {
      return {
        ...v,
        is_tax_calculated_manually: is_tax_calculated_manually,
        taxable_amount: +taxable_amount,
        recipients: recipientsData,
        tax_rate: +tax_rate,
        tax_amount: +tax,
      };
    });
  };

  //Reset the tax related data when payment_type = "house_account".
  const resetTaxCalRelatedData = useCallback(async () => {
    if ( formik.values.payment_type === "house_account") {
      formik.setValues((v) => {
        return {
          ...v,
          is_tax_calculated_manually: false,
          tax_rate: 0,
          tax_amount: 0,
          taxable_amount: 0,
          recipients: v.recipients.map((recipient) => {
            return {
              ...recipient,
              products: recipient.products.map((product) => {
                return {
                  ...product,
                  tax: 0,
                  tax_rate: 0,
                  taxable_amount: 0,
                  kintsugi_item_price: 0,
                };
              }),
              addons: recipient.addons.map((addon) => {
                return {
                  ...addon,
                  tax: 0,
                  tax_rate: 0,
                  taxable_amount: 0,
                  kintsugi_item_price: 0,
                };
              }),
            };
          }),
        };
      });
    }
  }, [formik.values.payment_type]);

  useEffect(() => {
    fetchTaxAmount();
  }, [fetchTaxAmount]);

  useEffect(() => {
    resetTaxCalRelatedData();
  }, [resetTaxCalRelatedData]);

  useEffect(() => {
    const summ = calcuateSummary(formik);
    setSummary(summ);
  }, [
    formik.values.recipients,
    formik.values.delivery_information.charge,
    formik.values.delivery_information.upcharge_amount,
    formik.values.discount,
    formik.values.tax_id,
    formik.values.payment_type,
    formik.values.taxable_amount,
  ]);

  useEffect(() => {
    setTempAddFundValue(summary.add_fund);
    setTempReFundValue(summary.refund);
  }, [summary.add_fund, summary.refund]);

  let delivery_charge =
    summary.delivery_charges === 0
      ? 0
      : +summary.delivery_charges + summary.upcharge_amount;

  const changeValue = (value) => {
    let val = value || "";
    setTempAddFundValue(val);
  };
  const changeValueRefund = (value) => {
    let val = value || "";
    setTempReFundValue(val);
  };
  // console.log("summary=>",summary);
  return (
    <div>
      <div className={`${styles.subTotalBlock}`}>
        {/* subtotal */}
        <div className={`${styles.totalBlockItem} mb-2`}>
          <span>Sub Total</span>
          <span>${summary.sub_total.toFixed(2)}</span>
        </div>
        {/* discount */}
        <div
          className={`${styles.totalBlockItem} align-items-center mb-2`}
          style={{ position: "relative" }}
        >
          <span>
            Discount
            <span
              className={`btn-sm outline-btn ml-3 cursor-pointer ${styles.totalBlockBtn}`}
              onClick={() => setShowDiscountModal(active === 0 ? true : false)}
            >
              + Discount
            </span>
            <br></br>
            {formik.values?.discount?.discount_promocode && (
              <span
                style={{
                  fontSize: "13px",
                  position: "absolute",
                  bottom: "-10px",
                  margin: 0,
                  padding: 0,
                  lineHeight: 1,
                }}
              >
                {"(Promo Code : " +
                  formik.values?.discount?.discount_promocode +
                  ")"}
              </span>
            )}
          </span>
          <span className={`${styles.borderBottom}`}>
            - ${parseFloat(summary.discount_price).toFixed(2)}
          </span>
        </div>
        {/* Subscription discount */}
        {orderDetail?.is_subscribe_order && (
          <div className={`${styles.totalBlockItem} align-items-center mb-2`}>
            <span>Subscription Discount</span>
            <span className={`${styles.borderBottom}`}>
              - ${summary.subscription_discount}
            </span>
          </div>
        )}

        {/* // redeem amount */}
        {module === "edit-order" && (
          <div className={`${styles.totalBlockItem} align-items-center mb-2`}>
            <span>User Redeem Amount</span>
            <span>
              $
              {formik.values.petal_reward.petal_rewards_point_amount.toFixed(2)}
            </span>
          </div>
        )}

        {/* tax */}
        <div className={`${styles.totalBlockItem} align-items-center mb-2`}>
          <span>
            Tax
            <span
              className={`btn-sm outline-btn ml-3 cursor-pointer ${styles.totalBlockBtn}`}
              onClick={() => setShowTaxModal(active === 0 ? true : false)}
            >
              + Tax ID
            </span>
          </span>
          <span>${summary.tax.toFixed(2)}</span>
        </div>
        {/* delivery charge */}
        <div className={`${styles.totalBlockItem} mb-2`}>
          <span>Service/Delivery</span>
          <span>${parseFloat(delivery_charge).toFixed(2)}</span>
        </div>

        {/* add fund and refund */}
        {module === "edit-order" && (
          <>
            <div className={`${styles.totalBlockItem} align-items-center mb-2`}>
              <span>
                Add Funds
                <span
                  className={`btn-sm outline-btn ml-3 cursor-pointer ${styles.totalBlockBtn}`}
                  onClick={() => {
                    if (+parseFloat(summary.refund).toFixed(0) > 0) {
                      showToastMessage(
                        undefined,
                        `Can not add fund while there is a refund amount available.`,
                        "error",
                        false
                      );
                      return;
                    }
                    if (active === 0) setModal("fund-edit");
                  }}
                >
                  + Add Funds
                </span>
              </span>
              <span>${parseFloat(summary.add_fund).toFixed(2)}</span>
            </div>
            <div className={`${styles.totalBlockItem} align-items-center mb-2`}>
              <span>
                Refunds
                <span
                  className={`btn-sm outline-btn ml-3 cursor-pointer ${styles.totalBlockBtn}`}
                  onClick={() => {
                    if (+parseFloat(summary.add_fund).toFixed(0) > 0) {
                      showToastMessage(
                        undefined,
                        `Can not refund while there is a add fund amount available.`,
                        "error",
                        false
                      );
                      return;
                    }
                    if (active === 0) setModal("refund-edit");
                  }}
                >
                  + Refund
                </span>
              </span>
              <span>-${parseFloat(summary.refund).toFixed(2)}</span>
            </div>
          </>
        )}
      </div>
      {/* total */}
      <div className={`${styles.totalBlock} mt-2`}>
        <div className={`${styles.mainTotalBlockItem} mb-2`}>
          <span>Total</span>
          <span>${summary.total.toFixed(2)}</span>
        </div>
        <div className={`${styles.totalBlockItem} align-items-center mb-2`}>
          <span style={{ fontSize: "14px" }}>
            (This amount is the total cart amount. Additional Add fund is not
            considered in this total).
          </span>
        </div>
        {/* General info of amount */}
        {module === "edit-order" && (
          <>
            <div
              className={`${styles.totalBlockItem} align-items-center mb-2`}
              style={{ fontSize: "14px" }}
            >
              <span>Amount recieved till now</span>
              <span>${formik.values.total_fund.toFixed(2)}</span>
            </div>
            <div
              className={`${styles.totalBlockItem} align-items-center mb-2`}
              style={{ fontSize: "14px" }}
            >
              <span>Amount refunded till now</span>
              <span>-${formik.values.total_refund.toFixed(2)}</span>
            </div>
          </>
        )}
        {/* discount reason */}
        {formik.values.discount.discount_reason && (
          <div>
            <div className={`${styles.totalBlockItem} mb-2`}>
              <span>Discount Reason</span>
            </div>
            <div className={`${styles.totalBlockItem} mb-2`}>
              <span>{formik.values.discount.discount_reason}</span>
            </div>
          </div>
        )}
      </div>
      <DiscountModal
        showModal={showDiscountModal}
        pageTitle="Add Discount"
        submitStatus={false}
        handleCancelClick={(e) => {
          setShowDiscountModal(false);
        }}
      />
      <TaxModal
        showModal={showTaxModal}
        pageTitle="Add Tax ID"
        handleCancelClick={(e) => {
          setShowTaxModal(false);
        }}
      />
      <FundModal
        open={modal === "fund-edit"}
        pageTitle={"Add Fund"}
        value={tempAddFundValue}
        onClose={() => {
          setTempAddFundValue(summary.add_fund);
          setModal("");
        }}
        onChange={changeValue}
        onSave={() => {
          setSummary({
            ...summary,
            add_fund: tempAddFundValue,
          });
          setModal("");
        }}
      />
      <FundModal
        open={modal === "refund-edit"}
        pageTitle={"Refund"}
        value={tempReFundValue}
        onClose={() => {
          setTempReFundValue(summary.refund);
          setModal("");
        }}
        onChange={changeValueRefund}
        onSave={() => {
          setSummary({
            ...summary,
            refund: tempReFundValue,
          });
          setModal("");
        }}
      />
    </div>
  );
};
export default TotalSummary;
