import React from "react";
import styles from "./index.module.scss";
import { DateTime } from "luxon";

const OrderSummery = (props) => {
  return (
    <div>
      <h3 className="bold mt-5">Order</h3>
      <div className={`row ${styles.OrderSummeryCard}`}>
        <div
          className={`${styles.OrderSummeryItem} col-12 col-lg-2 col-md-3 col-sm-12 col-xs-12`}
        >
          <div className={`${styles.OrderSummeryLabel}`}>Order Number</div>
          <div className={`${styles.OrderSummeryDetail}`}>
            {props.orderNumber}
          </div>
        </div>
        <div
          className={`${styles.OrderSummeryItem} col-12 col-lg-3 col-md-3 col-sm-12 col-xs-12`}
        >
          <div className={`${styles.OrderSummeryLabel}`}>Order Date</div>
          <div className={`${styles.OrderSummeryDetail}`}>
            {props.orderDate && props.orderDate !== null ? DateTime.fromISO(props.orderDate).setZone("America/Los_Angeles").toFormat("ccc LLL dd, yyyy, hh:mm a") : "-"}
          
          </div>
        </div>
        <div
          className={`${styles.OrderSummeryItem} col-12 col-lg-3 col-md-3 col-sm-12 col-xs-12`}
        >
          <div className={`${styles.OrderSummeryLabel}`}>Delivery Date</div>
          <div className={`${styles.OrderSummeryDetail}`}>
            {/* {props.deliveryDate && props.deliveryDate !== null ? DateTime.fromISO(props.deliveryDate).setZone("America/Los_Angeles").toFormat("ccc LLL dd, yyyy, hh:mm a") : "-"} */}
            {props.deliveryDate && props.deliveryDate !== null ? DateTime.fromISO(props.deliveryDate).setZone("America/Los_Angeles").toFormat("ccc LLL dd, yyyy") : "-"}
            {props.deliveryDate && props.deliveryDate !== null ? " "+props.delivery_time : "-"}
          </div>
        </div>
        <div
          className={`${styles.OrderSummeryItem} col-12 col-lg-2 col-md-3 col-sm-12 col-xs-12`}
        >
          <div className={`${styles.OrderSummeryLabel}`}>Delivery Status</div>
          <div className={`${styles.OrderSummeryDetail}`}>
            {props.deliveryStatus}
          </div>
        </div>
        <div
          className={`${styles.OrderSummeryItem} col-12 col-lg-2 col-md-3 col-sm-12 col-xs-12`}
        >
          <div className={`${styles.OrderSummeryLabel}`}>Time Delivered</div>
          <div className={`${styles.OrderSummeryDetail}`}>
            {props.timeDelivered && props.timeDelivered !== null ? DateTime.fromISO(props.timeDelivered).setZone("America/Los_Angeles").toFormat("hh:mm a") : "-"} 
          </div>
        </div>
        <div
          className={`${styles.OrderSummeryItem} col-12 col-lg-2 col-md-3 col-sm-12 col-xs-12 mt-3`}
        >
          <div className={`${styles.OrderSummeryLabel}`}>Order Taken By</div>
          <div className={`${styles.OrderSummeryDetail}`}>{props.takenBy}</div>
        </div>
        <div
          className={`${styles.OrderSummeryItem} col-12 col-lg-2 col-md-3 col-sm-12 col-xs-12 mt-3`}
        >
          <div className={`${styles.OrderSummeryLabel}`}>Designed By</div>
          <div className={`${styles.OrderSummeryDetail}`}>
            {props.designedBy}
          </div>          
        </div>
        <div className={`${styles.OrderSummeryItem} col-12 col-lg-2 col-md-3 col-sm-12 col-xs-12 mt-3`}>
          <div className={`${styles.OrderSummeryLabel}`}>Driver Name</div>
          <div className={`${styles.OrderSummeryDetail}`}>
            {props.driverName }
          </div>
        </div>
        <div className={`${styles.OrderSummeryItem} ${styles.OrderSummerySignatureItem} col-12 col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-3`}
        >
          <div className={`${styles.OrderSummeryDetail}`}>
            <img
              src={props.signature}
              alt="signature"
              style={{ width: "100px", height: "85px" }}
            />
          </div>
          <div className={`${styles.OrderSummeryLabel}`}>
            Recipient signature or Picture from driver
          </div>
        </div>
        <div
          className={`${styles.OrderSummeryItem} col-12 col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-3`}
        >
          <div className={`${styles.OrderSummeryLabel}`}>Source</div>
          <div className={`${styles.OrderSummeryDetail}`}>{props.source}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummery;
