import Route from "react-router-hooks";
import { BrowserRouter, Switch } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import routes from "./Routes/Routes";
import LoaderGif from "./Assets/loader.gif";
// Scss
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.css";
import "./Assets/scss/material-kit.scss?v=2.2.0";

// Components
import Sidebar from "./Components/Sidebar/Sidebar";
import socket from "./socket"
// Context
import { AppContext } from "./Context/AppContext";

// Cookies
import Cookies from "universal-cookie";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
const cookies = new Cookies();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ca998d",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        paddingTop: 8,
      },
    },
    MuiButton: {
      containedPrimary: {
        textTransform: "capitalize",
        fontWeight: "700",
        fontSize: "20px !important",
        lineHeight: 1.5,
        "&:hover, &:focus": {
          backgroundColor: "#c2887b",
          color: "#fff",
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 20,
        fontWeight: 300,
        color: "#AAA",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 20,
      },
      input: {
        boxSizing: "border-box",
        height: "auto",
        padding: "6px 0 10px",
      },
    },
  },
});

const App = () => {
  useEffect(() => {
    if (window.location.pathname !== "/admin/orders/designer-status") {
      window.scrollTo({ top: 0, left: 0 });
    }
  }, [window.location.href]);

  const { isLogin, isLoading, alert , showToastMessage,} = useContext(AppContext);
  useEffect(async () => {
    socket.on('send_notification', (data) => {
      showToastMessage(data.notificationPayload.title, data.notificationPayload.message, "info", false,10000);
    })
  },[socket]);
  const requireAuth = (nextState, replace) => {
    var allowPath = ["/login", "/forgot-password", "/reset-password"];

    if (cookies.get("accessToken")) {
      if (allowPath.includes(nextState.path))
        replace({ pathname: "/admin/orders/list" });
    } else {
      if (!allowPath.includes(nextState.path)) replace({ pathname: "/login" });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className={isLogin ? "wrapper" : ""}>
          {isLogin && <Sidebar />}
          <div
            className={
              !isLogin ? "content-login-wrapper" : "content-wrapper rightPanel"
            }
          >
            {alert}

            {isLoading && (
              <div className="loader-wrapper">
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  {/* <Loader type="ThreeDots" color="#0069d9" height={50} width={50} /> */}
                  <img
                    style={{ width: "500px" }}
                    src={LoaderGif}
                    alt="Loader"
                  />
                </div>
              </div>
            )}

            <Switch>
              {routes.map((route, index) =>
                route.children.map((route, childrenIndex) => (
                  <Route
                    key={childrenIndex}
                    path={route.path}
                    exact={route.exact}
                    onEnter={requireAuth}
                    component={route.component}
                    accessPermission={route.accessPermission}
                  />
                ))
              )}
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
