import { getCustomerWithOrderDetails } from "api/customer";
import { getOrderDetailsById, getOrderEditByDetail } from "api/order";
import { getProducts } from "api/product";
import LoadingScreen from "Components/Loading/LoadingScreen";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useOrderAttributeProvider } from "../context/order";
import { setDataToContext } from "../utility/setDataToContext";

const FetchDetail = (props) => {
  const formik = useFormikContext();
  const { setOrderDetails } = useOrderAttributeProvider();
  const history = useHistory();
  const [loading, setloading] = useState(true);

  const fetchUserDetails = React.useCallback(async () => {
    if (props.module === "new-order" && props.id) {
      try {
        const response = await getCustomerWithOrderDetails({ id: props.id });
        let customer_info = { ...formik.values.customer_info };
        let cust_data = response.data.data.customer;
        customer_info.customer_id = cust_data.id;
        customer_info.customer_first_name = cust_data.first_name;
        customer_info.customer_last_name = cust_data.last_name;
        customer_info.customer_phone = cust_data.phone;
        customer_info.customer_phone_country_code = cust_data.country_code;
        customer_info.customer_email = cust_data.email;
        customer_info.customer_alt_phone = cust_data.alt_phone;
        customer_info.customer_alt_phone_country_code = cust_data.alt_country_code;
        customer_info.is_house_account = cust_data.is_house_account; 
        customer_info.is_business_account = cust_data.is_business_account;
        customer_info.braintree_customer_id = cust_data.braintree_customer_id;
        formik.setValues((v) => {
          return {
            ...v,
            customer_info,
          };
        });
        setloading(false);
      } catch (error) {
        setloading(false);
      }
    } else if (props.module === "new-order" && !props.id) {
      setloading(false);
    }
  }, []);

  const fetchOrderDetails = React.useCallback(async () => {
    if (props.module !== "new-order" && props.id) {
      try {
        const response = await getOrderDetailsById(props.id);
        let orderData = response.data.data;
        if (orderData.old_order_number > 0) {
          history.push("/admin/orders/list");
        }
        let productIds = orderData.order_recipients.map((reci) => {
          return reci.order_products.map((z) => z.product_id);
        });
        let product_ids = productIds.flat(1);
        const prResp = await getProducts({
          sortBy: [],
          filters: [
            {
              id: "id",
              value: product_ids,
            },
          ],
          itemsPerPage:product_ids.length,
        });

        let postData = {
          orderId: props.id,
        };
        const editDetailData = await getOrderEditByDetail(postData);

        orderData = { ...orderData, ...editDetailData.data.data, ...{'editOrderMessage':editDetailData.data.message} };

        setOrderDetails(orderData);
        let contextData = await setDataToContext(
          orderData,
          props.module,
          prResp.data.data.rows,
          editDetailData.data.data
        );
        
        if(props.module !== "edit-order") {
          contextData.is_burq_order = 0;
        }
        
        formik.setValues((v) => {
          return {
            ...v,
            ...contextData,
          };
        });
        setloading(false);
      } catch (error) {
        console.log("Error fetching order details");
        setloading(false);
      }
    }
  }, []);

  // const checkOrderEditBy = React.useCallback(async () => {
  //   try {
  //     if (props.module !== "new-order" && props.id) {
  //       let postData = {
  //         orderId:props.id
  //       }
  //       const response = await getOrderEditByDetail(postData);
  //       setloading(false);
  //     }
  //   }catch (error) {
  //     console.log("Error fetching order details");
  //     setloading(false);
  //   }
  // })

  useEffect(() => {
    fetchUserDetails();
    fetchOrderDetails();
    // checkOrderEditBy();
  }, [fetchUserDetails, fetchOrderDetails]);

  if (loading) return <LoadingScreen />;

  return <></>;
};

export default FetchDetail;
