import { getCustomerWithOrderDetails } from "api/customer";
import { DEFAULT_COUNTRY_CODE } from "Helpers/Constants";
import moment from "moment";
import { ISOtoDateTime, utcISOStringToPSTDate } from "./util";

export async function setDataToContext(orderDetail, module, products) {
  let order_type_identifier = orderDetail.order_type_identifier;
  let replaceOrDuplicate = false;
  let is_replace_order = false;
  let internal_note = "";
  if (module === "replace-order") {
    order_type_identifier = 1;
    replaceOrDuplicate = true;
    is_replace_order = true;
    internal_note = `Replace order for order number ${orderDetail?.order_number}`;
  }

  if (module === "duplicate-order") {
    order_type_identifier = 2;
    replaceOrDuplicate = true;
    internal_note = `Duplicate order for order number ${orderDetail?.order_number}`;
  }

  let recipients = orderDetail.order_recipients.map((recipient, recIndex) => {
    let prods = recipient.order_products
      .filter((z) => !z.product_type)
      .map((c,prodIndex) => {

        let prod = products.find((x) => c.product_id === x.id);
        let selectedVariant = prod.product_variations.find(
          (v) => v.id === c.variation_id
        );
        selectedVariant.price = selectedVariant.sale_price;

        let total_price = selectedVariant.price * c.qty;
        if (orderDetail.is_custom_order === 1) {
          total_price = c.total_price;
          prod.title = c.product_name;
          selectedVariant.price = c.unit_price;
          selectedVariant.image = c.variation_image;
          selectedVariant.title = c.variation_name;
        }
        if (orderDetail.is_custom_order === 1) {
          selectedVariant = { ...selectedVariant, title: c.variation_name }
        }
        return {
          ...prod,
          sql_id: c.id,
          qty: c.qty,
          selectedVariant,
          total_price: total_price,
          custom_product_flag: c.custom_product_flag,
          tax_rate: is_replace_order ? 0 : c?.tax_rate,
          taxable_amount: is_replace_order ? 0 : c?.taxable_amount,
          tax: is_replace_order ? 0 : c?.tax,
          kintsugi_item_price: is_replace_order ? 0 : c?.kintsugi_item_price,
          product_key: `prod_${recIndex}_${prodIndex}_${c.product_id}_${c.variation_id}_${new Date().getTime()}`,
        };
      });
    let addons = recipient.order_products
      .filter((z) => z.product_type)
      .map((c,prodIndex) => {
        let prod = products.find((x) => c.product_id === x.id);
        let selectedVariant = prod.product_variations.find(
          (v) => v.id === c.variation_id
        );
        selectedVariant.price = c.unit_price;
        return {
          ...prod,
          sql_id: c.id,
          qty: c.qty,
          selectedVariant,
          total_price: c.total_price,
          tax_rate: is_replace_order ? 0 : c?.tax_rate,
          taxable_amount: is_replace_order ? 0 :  c?.taxable_amount,
          tax: is_replace_order ? 0 : c?.tax,
          kintsugi_item_price: is_replace_order ? 0 : c?.kintsugi_item_price,
          product_key: `prod_${recIndex}_${prodIndex}_${c.product_id}_${c.variation_id}_${new Date().getTime()}`,
        };
      });

    return {
      sql_id: recipient.id,
      recipient_id: recipient.recipients_id,
      products: prods,
      addons: addons,
      first_name: recipient.first_name,
      last_name: recipient.last_name,
      whats_the_occasion: recipient.whats_the_occasion,
      whats_the_occasion_id: recipient.whats_the_occasion_id,
      greetings_on_card: recipient.greetings_on_card,
      card_signature: recipient.card_signature,
      video_url: recipient?.video_url ?? "",
      video_message: recipient?.video_message ?? "",
      video_message_pass_code: recipient?.video_message_pass_code ?? "",
      video_message_qr_code: recipient?.video_message_qr_code ?? "",
      deleted_product_ids: [],
      recipient_key: `rec_${recIndex}_${recipient.id}_${new Date().getTime()}`,
    };
  });

  let de = orderDetail.order_recipients[0];

  let delivery_information = {
    address_type_id: de.address_type_id,
    address_type: de.address_type,
    address_line_1: de.address_line_1,
    address_line_2: de.address_line_2,
    phone: de.phone,
    phone_country_code: de.phone_country_code ? de.phone_country_code : DEFAULT_COUNTRY_CODE,
    alt_phone: de.alt_phone,
    alt_phone_country_code: de.alt_phone_country_code ? de.alt_phone_country_code : DEFAULT_COUNTRY_CODE,
    city: de.city,
    state: de.state,
    attn: de.attn,
    zipcode: de.zipcode,
    country: "usa",
    is_local: orderDetail.is_local_order,
    frequency: orderDetail?.subscription_order?.frequency ?? null,
    delivery_date: replaceOrDuplicate
      ? null
      : utcISOStringToPSTDate(orderDetail.delivery_date_time),
    delivery_time_id: replaceOrDuplicate
      ? 0
      : orderDetail.is_custom_delivery_matrix === 1
        ? -1
        : orderDetail.delivered_time_id,
    delivery_time: replaceOrDuplicate ? "" : orderDetail.delivered_time,
    delivery_date_time: replaceOrDuplicate
      ? ""
      : ISOtoDateTime(orderDetail.delivery_date_time),
    delivery_time_type: replaceOrDuplicate
      ? ""
      : orderDetail.delivery_time_type,
    delivery_type: orderDetail.delivery_type,
    actual_delivered_time: "",
    charge: replaceOrDuplicate ? 0 : orderDetail.delivery_charges,
    upcharge_id: replaceOrDuplicate ? 0 : orderDetail.upcharge_id,
    upcharge_amount: replaceOrDuplicate ? 0 : orderDetail.upcharge_amount,
    driver_instruction: orderDetail.driver_instruction,
    designer_instruction: orderDetail.designer_instruction,
    delivery_type_id: orderDetail.delivery_type_id,
    storeId:orderDetail.store.id,
    order_type: orderDetail.order_type ? 1 : 0,
    shop_code: orderDetail.shopcode ?? "",
    shop_name: orderDetail.external_shop_name ?? "",
    person_name: orderDetail.external_person_name ?? "",
    external_order_number: orderDetail.external_order_number ?? "",
    phone_number: orderDetail.external_phone_number ?? "",
  };
  let billing_info = {
    customer_billing_first_name:
      orderDetail.order_transaction.customer_billing_first_name,
    customer_billing_last_name:
      orderDetail.order_transaction.customer_billing_last_name,
    customer_billing_address_2: "",
    customer_billing_address_1:
      orderDetail.order_transaction.customer_billing_address_1,
    customer_billing_city: orderDetail.order_transaction.customer_billing_city,
    customer_billing_state:
      orderDetail.order_transaction.customer_billing_state,
    customer_billing_country:
      orderDetail.order_transaction.customer_billing_country,
    customer_billing_zipcode:
      orderDetail.order_transaction.customer_billing_zipcode,
  };
  // await getCustomerWithOrderDetails({ id: orderData.order_transaction.customer_id })
  let customer_info = {};
  if (orderDetail.order_transaction.customer_id !== 0) {
    const resp = await getCustomerWithOrderDetails({
      id: orderDetail.order_transaction.customer_id,
    });
    let cust_data = resp.data.data.customer;
    customer_info.customer_id = cust_data.id;
    customer_info.customer_first_name = cust_data.first_name;
    customer_info.customer_last_name = cust_data.last_name;
    customer_info.customer_phone = cust_data.phone;
    customer_info.customer_phone_country_code = cust_data.country_code ? cust_data.country_code : DEFAULT_COUNTRY_CODE;
    customer_info.customer_email = cust_data.email;
    customer_info.customer_alt_phone = cust_data.alt_phone;
    customer_info.customer_alt_phone_country_code = cust_data.alt_country_code ? cust_data.alt_country_code : DEFAULT_COUNTRY_CODE;
    customer_info.is_house_account = cust_data.is_house_account;
    customer_info.is_business_account = cust_data.is_business_account;
    customer_info.braintree_customer_id = cust_data.braintree_customer_id;
  } else {
    customer_info.customer_id = orderDetail.order_transaction.customer_id;
    customer_info.customer_first_name =
      orderDetail.order_transaction.customer_first_name;
    customer_info.customer_last_name =
      orderDetail.order_transaction.customer_last_name;
    customer_info.customer_phone = orderDetail.order_transaction.customer_phone;
    customer_info.customer_phone_country_code = orderDetail.order_transaction.customer_phone_country_code ? orderDetail.order_transaction.customer_phone_country_code : DEFAULT_COUNTRY_CODE;
    customer_info.customer_email = orderDetail.order_transaction.customer_email;
    customer_info.customer_alt_phone =
      orderDetail.order_transaction.customer_alt_phone;
    customer_info.customer_alt_phone_country_code =
      orderDetail.order_transaction.customer_alt_phone_country_code ? orderDetail.order_transaction.customer_alt_phone_country_code : DEFAULT_COUNTRY_CODE;
    customer_info.is_house_account = 0;
    customer_info.is_business_account = 0;
    customer_info.braintree_customer_id = null;
  }
  let dt = orderDetail.source === 'csr' && orderDetail.discount_percentage > 0 ? "1" : "0";
  let discount_percent = orderDetail.source !== 'csr' ? 0 : orderDetail.discount_percentage;
  let discount = {
    discount_type: dt,
    discount_reason:
      order_type_identifier === 1
        ? "Replace order discount"
        : orderDetail.custom_reason_for_discount,
    discount_percentage:
      order_type_identifier === 1 ? 100 : discount_percent,
    discount_price: orderDetail.discount_price,
    discount_promocode: orderDetail.promocode
  };

  let petal_reward = {
    petal_rewards_id:
      module === "edit-order" ? orderDetail.petal_rewards_id : 0,
    petal_rewards_point_applied:
      module === "edit-order" ? orderDetail.petal_rewards_point_applied : 0,
    petal_rewards_point_amount:
      module === "edit-order" ? orderDetail.petal_rewards_point_amount : 0,
  };

  const internal_notes =
    orderDetail?.order_internal_notes.length > 0
      ? orderDetail?.order_internal_notes?.map((o) => {
        return {
          author: o?.user?.first_name + " " + o?.user?.last_name,
          ans: o.notes,
          time: moment(o.createdAt).toISOString(),
        };
      })
      : [];

  let payment_type =
    orderDetail?.order_transaction?.order_payment_transactions[0]
      ?.transaction_type;

  const setValue = {
    add_fund: 0,
    refund: 0,
    reason_message: orderDetail.reason_message ?? "",
    reason_message_option: orderDetail.reason_message_option ?? 0,
    refund_flag: orderDetail.refund_flag ?? 0,
    verified: false,
    deleted_recipient_ids: [],
    is_burq_order: orderDetail.is_burq_order,
    is_cancel_fail_by_burq: orderDetail.is_cancel_fail_by_burq,
    is_subscribe_order: orderDetail.is_subscribe_order ? 1 : 0,
    replaceOrDuplicate: replaceOrDuplicate,
    mainTotal: orderDetail.total,
    total_fund: orderDetail.total_fund_amount ?? 0,
    total_refund: orderDetail.total_refund_amount ?? 0,
    total_refund_pending: (orderDetail.total_fund_amount > 0 && orderDetail.total_refund_amount > 0) ? (orderDetail.total_fund_amount - orderDetail.total_refund_amount).toFixed(2) : orderDetail.total_fund_amount > 0 ? orderDetail.total_fund_amount : 0,
    subscription_discount: replaceOrDuplicate
      ? 0
      : orderDetail.subscription_discount,
    custom_order: orderDetail.is_custom_order,
    order_type_identifier: order_type_identifier,
    parent_order_id: replaceOrDuplicate
      ? orderDetail.id
      : orderDetail.parent_order_id,
    recipients: recipients,
    delivery_information,
    billing_info,
    customer_info: customer_info,
    payment_type:
      module === "edit-order"
        ? payment_type
        : module === "replace-order"
          ? "replace_order"
          : "",
    discount: discount,
    tax_id: orderDetail.tax_id,
    internal_notes,
    internal_note: internal_note,
    petal_reward,
    tax_amount: is_replace_order ? 0 : orderDetail?.tax,
    is_tax_calculated_manually: is_replace_order ? false : orderDetail?.is_tax_calculated_manually,
    tax_rate: is_replace_order ? 0 : orderDetail?.tax_rate,
    taxable_amount: is_replace_order ? 0 : orderDetail?.taxable_amount,
    is_replace_order : is_replace_order,
  };

  return setValue;
}
