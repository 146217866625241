import React from "react";
import { AppProvider } from "./AppContext";
import ProductProvider from "./Product/ProductContext";
import AddonProvider from "./Addon/AddonContext";
import OrderProvider from "./OrderContext/orderContext";
import UserDataProvider from "./UserContext/userContext";
import HomePageProvider from "./Cms-home-page/homePageContext";
import ProductProviderCurd from "./ProductCrud/productContext";
import CategoryProviderCurd from "./CategoryCurd/CategoryContext";
import BlogProvider from "./Cms-blog/blogContext";
import CouponProviderCurd from "./CouponCurd/CouponContext";
import CmsPageProviderCurd from "./Cms-page/cmsPageContext";
import DeliveryMatrixCurd from "./DeliveryMatrixCurd/DeliveryMatrixContext";
import LocationProviderCurd from "./Cms-Locations/locationContext";

const Store = (props) => {
  return (
    <AppProvider>
      <UserDataProvider>
        <OrderProvider>
          <HomePageProvider>
            <ProductProvider>
              <AddonProvider>
                <ProductProviderCurd>
                  <CategoryProviderCurd>
                    <BlogProvider>
                      <CouponProviderCurd>
                        <DeliveryMatrixCurd>
                          <CmsPageProviderCurd>
                            <LocationProviderCurd>
                              {props.children}
                            </LocationProviderCurd>
                          </CmsPageProviderCurd>
                        </DeliveryMatrixCurd>
                      </CouponProviderCurd>
                    </BlogProvider>
                  </CategoryProviderCurd>
                </ProductProviderCurd>
              </AddonProvider>
            </ProductProvider>
          </HomePageProvider>
        </OrderProvider>
      </UserDataProvider>
    </AppProvider>
  );
};

export default Store;
